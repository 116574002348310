/* @ngInject */
export default class NotificationItemController {
  constructor($scope, $mdDialog, $injector, Config) {
    this._$scope = $scope;
    this._$mdDialog = $mdDialog;
    this._DeviceService = $injector.get("DeviceService");
    this._ActionService = $injector.get("ActionService");
    this._InboxItemService = $injector.get("InboxItemService");

    this.features = Config.features;
  }

  /**
   * Gets the user friendly display name for an action
   *
   * @param {Object} action
   * @returns {string}
   *
   * @memberOf module:app/inbox.notificationItemController
   * @see SRS: BR-1246
   */
  getActionDisplayName(action) {
    const actionType = action.subType || action.name;
    return this._ActionService.userFriendlyTypeNames[actionType] || actionType;
  }

  /**
   * Gets the user friendly displayed action data for an action
   *
   * @param {Object} action
   * @returns {string}
   *
   * @memberOf module:app/inbox.notificationItemController
   * @see SRS: BR-1246
   */
  getDisplayedActionData(action) {
    return this._ActionService.getUserFriendlyActionData(action);
  }

  _getStudyTypeFromAction(action) {
    let studyType = "";
    if (action.data) {
      try {
        const parsedData = JSON.parse(action.data);
        studyType = parsedData.studyType;
      } catch (e) {
        /* Do nothing */
      }
    }
    return studyType;
  }
}

import angular from "angular";

import {angularizeDirective} from "../../shared/react-in-angular.js";
import UserClassificationChipWrapper from "./UserClassificationChipWrapper.jsx";

export default angular.module("app.components.userClassificationChip", []).name;

// matches <br-user-classification-chip>
angularizeDirective(
  UserClassificationChipWrapper,
  "brUserClassificationChip",
  angular.module("app.components.userClassificationChip"),
  {
    id: "=",
    disabled: "=",
    user: "=",
    algorithm: "=",
  }
);

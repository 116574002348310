/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

import {useLocalStorage} from "@tzmedical/react-hooks";

import useUserReadableRoles from "./useUserReadableRoles.jsx";

const JwtContext = React.createContext();

/**
 *
 * @param {String} jwt the base64 encoded raw JWT
 * @throws Will throw an Error if the JWT is not valid for access to the application
 */
export function checkJwt(jwt) {
  const jwtPayload = jwt && JSON.parse(window.atob(jwt.split(".")[1]));

  // TODO: fix this error with human-readable text after converting login page to React
  if (!jwtPayload) {
    throw new Error("loggedOutAnotherTab");
  }

  const expirationTime = jwtPayload.exp;
  const currentTime = Math.floor(Date.now() / 1000);
  const jwtIsExpired = expirationTime < currentTime;
  const allowedRoles = ["clinicalStaff", "facilityAdmin", "physician", "tech", "triageTech", "tzAdmin"];

  // TODO: fix these errors with human-readable ones after converting login page to React
  if (jwtIsExpired === true) {
    throw new Error("autoLoggedOut");
    /* c8 ignore next 7 - to get there, we'd need to generate an up-to-date JWT for each test run */
  }
  if (!jwtPayload.user.facility.inboxAccess) {
    throw new Error("unauthorizedInboxAccess");
  }
  if (!allowedRoles.includes(jwtPayload.user.role)) {
    throw new Error("unauthorized");
  }
}

export function JwtProvider({children}) {
  // NOTE: if you change the id of this, update axiosClient.js too
  const [jwt, setJwt] = useLocalStorage("jwt", null);

  const jwtPayload = React.useMemo(() => jwt && JSON.parse(window.atob(jwt.split(".")[1])), [jwt]);
  const unauthorized = React.useMemo(() => {
    try {
      checkJwt(jwt);
      return false;
    } catch (err) {
      return err.message;
    }
  }, [jwt]);

  const userReadableRoles = useUserReadableRoles();

  const value = React.useMemo(() => {
    return {
      jwt,
      setJwt,
      unauthorized,
      expiration: jwtPayload?.exp,
      // User payload values
      email: jwtPayload?.user?.email,
      facilityDisabled: jwtPayload?.user?.facility?.disabled,
      fullName: jwtPayload?.user?.fullName,
      inboxAccess: jwtPayload?.user?.facility?.inboxAccess,
      /**
       * Checks if user role matches any of the passed-in roles
       * @param {Array<String>} roles - the roles to check if user role matches
       * @return {boolean}
       */
      isInAnyRole: (roles) => roles?.includes(jwtPayload?.user?.role),
      userFacilityBilling: jwtPayload?.user?.facility?.billing,
      userFacilityId: jwtPayload?.user?.facilityId,
      userFacilityName: jwtPayload?.user?.facility?.name,
      userId: jwtPayload?.user.id,
      username: jwtPayload?.user?.username,
      userReadableRole: userReadableRoles[jwtPayload?.user?.role] || "Unknown",
      facilityAccountId: jwtPayload?.user?.facility?.accountId,
    };
  }, [jwt, jwtPayload, setJwt, unauthorized, userReadableRoles]);

  return <JwtContext.Provider value={value}>{children}</JwtContext.Provider>;
}

JwtProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useJwt() {
  return React.useContext(JwtContext);
}

export default useJwt;

/* eslint-env browser */
import React from "react";

function useArrayReducer() {
  return React.useCallback((elements, action) => {
    switch (action.type) {
      case "init": {
        if (!Array.isArray(action.elements)) {
          throw Error("Elements must be initialized as an array");
        }

        return action.elements;
      }

      case "created": {
        if (!action.newElement || typeof action.newElement !== "object") {
          throw Error("Missing new element object");
        }

        return [...elements, action.newElement];
      }

      case "updated": {
        if (
          !action.updatedElement ||
          typeof action.updatedElement !== "object" ||
          !action.updatedElement?.id
        ) {
          throw Error("Missing updated element object");
        }

        return elements.map((element) => {
          if (element.id === action.updatedElement.id) {
            return action.updatedElement;
          }
          return element;
        });
      }

      case "deleted": {
        if (!action.id) {
          throw Error("Missing ID of deleted element");
        }

        return elements.filter((element) => element.id !== action.id);
      }

      default: {
        throw Error(`Unknown action: ${action.type}`);
      }
    }
  }, []);
}

export default useArrayReducer;

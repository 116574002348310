/* eslint-env browser */
import angular from "angular";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";

import itemConfirmationDialogPug from "../../../dialogs/itemConfirmationDialog/itemConfirmationDialog.pug";
import {formatDateAndTime} from "../../DateAndTime/DateAndTime.jsx";

/* @ngInject */
export default class EcgEventItemController {
  constructor($scope, Config, $injector, $mdDialog, $state) {
    this._$scope = $scope;
    this._$state = $state;
    this._Authentication = $injector.get("Authentication");
    this._InboxItemService = $injector.get("InboxItemService");
    this._GeneratedReportService = $injector.get("GeneratedReportService");
    this._StripService = $injector.get("StripService");
    this._StudyService = $injector.get("StudyService");
    this._EventService = $injector.get("EventService");
    this._$window = $injector.get("$window");
    this._Markers = $injector.get("Markers");
    this._Facility = $injector.get("Facility");
    this._WorkflowsService = $injector.get("WorkflowsService");
    this._StripClassificationsService = $injector.get("StripClassificationsService");
    this._ItemAssignmentService = $injector.get("ItemAssignmentService");
    this._EcgNavigation = $injector.get("EcgNavigation");
    this._$mdDialog = $mdDialog;
    this._$rootScope = $injector.get("$rootScope");
    this._backendConfig = $injector.get("backendConfig");

    this._Config = Config;
    this.features = Config.features;
    this.loadingReport = false;
    this.userRhythmClassifications = [
      "Artifact",
      "Lead Off",
      "AF",
      "Brady",
      "Normal",
      "Tachy",
      "VTach",
      "Other",
    ];
    this.ecgStripViewerConfig = angular.copy(Config.ecgStripViewerConfig);
    this.sequenceViewerConfig = angular.copy(Config.sequenceViewerConfig);
    this.fullViewerConfig = angular.copy(Config.fullViewerConfig);
    this.channels = [
      {
        display: this.ecgStripViewerConfig.displayElements.leads.I,
        invert: this.ecgStripViewerConfig.inversionStatus.leads.I,
      },
      {
        display: this.ecgStripViewerConfig.displayElements.leads.II,
        invert: this.ecgStripViewerConfig.inversionStatus.leads.II,
      },
      {
        display: this.ecgStripViewerConfig.displayElements.leads.III,
        invert: this.ecgStripViewerConfig.inversionStatus.leads.III,
      },
    ];

    this.selectedStripClassification = "";
    this.stripComments = "";

    this.technicianFindingsModel = "";
    this.meetsMdnCriteriaModel = false;

    const deregisterAddBeatMarker = this._$rootScope.$on("beat-marker-created", (emittedEvent, marker) => {
      this.addBeatMarker(marker);
    });
    this._$scope.$on("$destroy", deregisterAddBeatMarker);

    const deregisterDeleteBeatMarker = this._$rootScope.$on("beat-marker-deleted", (emittedEvent, marker) => {
      this.deleteBeatMarker(marker);
    });
    this._$scope.$on("$destroy", deregisterDeleteBeatMarker);

    this.$onInit = this._init;
  }

  /// Public Functions ///

  get defaultStripValues() {
    const {startTime, endTime} = this._getDefaultStartAndEndTime();

    const strip = {
      deviceClassification: this.item.type,
      eventId: this.item.id,
      studyId: this.item.studyId,
      enrollmentId: this.item.enrollmentId,
      facilityId: this.item.facilityId,
      createdBy: this._Authentication.getFullName(),
      timeBase: this.item.ecg.mmPerSecond || 25,
      gain: this.item.ecg.mmPerMillivolt || 10,
      startTime,
      endTime,
      order: 1,
      displayedLeads: {
        I: this.channels[0].display,
        II: this.channels[1].display,
        III: this.channels[2].display,
      },
      invertedChannels: {
        I: this.channels[0].invert,
        II: this.channels[1].invert,
        III: this.channels[2].invert,
      },
      measurements: this._Markers.DEFAULT_MEASUREMENTS,
      sequenceLead: "II",
      validStartTime: moment(startTime).year() >= 2010,
    };

    strip.middleSample = this._StripService.getStripMidpoint(strip, this.item.ecg);

    return {...strip};
  }

  updateTechnicianInputs() {
    this.technicianFindings = this.technicianFindingsModel;
    this.meetsMdnCriteria = this.meetsMdnCriteriaModel;
  }

  userRhythmClassificationSelected(chipName) {
    if (this.selectedUserRhythmClassification === chipName) {
      this.selectedUserRhythmClassification = null;
    } else {
      this.selectedUserRhythmClassification = chipName;
    }
  }

  swapOrders(clickedStrip, newOrder) {
    const oldOrder = clickedStrip.order;
    const otherStrip = this.item.eventStrips.find((strip) => strip.order === newOrder);
    clickedStrip.order = newOrder;
    otherStrip.order = oldOrder;
    // update viewer
    this._$rootScope.$emit("viewer-settings-updated");
  }

  deleteStrip(strip) {
    const removedStripOrder = strip.order;
    let stripToSelect;
    const indexToRemove = this.item.eventStrips.findIndex((savedStrip) => savedStrip.order === strip.order);
    // splice array at that index, removing the strip
    this.item.eventStrips.splice(indexToRemove, 1);
    const stripIsSelected = this.item.eventStrips.some((savedStrip) => savedStrip.isSelected);
    // iterate over array and update orders > that order
    this.item.eventStrips.forEach((savedStrip) => {
      if (savedStrip.order > removedStripOrder) {
        savedStrip.order--;
      }
      // If a different strip was already selected, that one should still be selected
      // If the deleted strip was selected, select the one that now has its order or the one above
      if (!stripIsSelected && savedStrip.order === removedStripOrder) {
        stripToSelect = savedStrip;
      } else if (!stripIsSelected && savedStrip.order === removedStripOrder - 1) {
        stripToSelect = savedStrip;
      }
    });

    if (stripIsSelected) {
      // If a different strip was selected, just redraw the canvas
      this._$rootScope.$emit("viewer-settings-updated");
    } else if (stripToSelect) {
      // Otherwise the deleted strip was selected, so select the new strip
      this.selectStrip(stripToSelect);
    }
  }

  async addBeatMarker(beatMarker) {
    const eventType = this._EventService.getEcgEventType(this.item.type);
    const formattedBeatMarker = {
      name: beatMarker.name,
      enrollmentId: this.item.enrollmentId,
      facilityId: this.item.facilityId,
      eventId: this.item.id,
      eventType,
      timestamp: beatMarker.timestamp,
      sampleOffset: beatMarker.sampleOffset,
    };
    try {
      const {
        data: [createdBeatMarker],
      } = await this._Markers.createEventBeatMarkers(this.item, [formattedBeatMarker]);
      beatMarker.id = createdBeatMarker.id;
    } catch (error) {
      console.error(error);

      const popupTitle = "Failed to save beat marker";
      const errorMessage = "Unable to save changes for this event beat marker.";

      // display error dialog
      await this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title(popupTitle)
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }
  }

  async deleteBeatMarker(beatMarker) {
    try {
      await this._Markers.deleteEventBeatMarker(beatMarker.id);
    } catch (error) {
      console.error(error);

      const popupTitle = "Failed to delete beat marker";
      const errorMessage = "Unable to save changes for this event beat marker.";

      // display error dialog
      await this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title(popupTitle)
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }
  }

  getDisplayedTime(stripTime) {
    return formatDateAndTime({datetime: stripTime, zone: this.item.study?.timeZone, seconds: true});
  }

  /**
   * @returns {String} a readable version of the leads that are enabled for a strip
   * @param {Object} displayedLeads indicates which leads are enabled
   */
  getDisplayedLeadsString(displayedLeads) {
    const displayedLeadsList = [];
    if (displayedLeads.I && this.item.ecg.leads.length > 0) {
      displayedLeadsList.push(this.item.ecg.leads[0].leadName);
    }
    if (displayedLeads.II && this.item.ecg.leads.length > 1) {
      displayedLeadsList.push(this.item.ecg.leads[1].leadName);
    }
    if (displayedLeads.III && this.item.ecg.leads.length > 2) {
      displayedLeadsList.push(this.item.ecg.leads[2].leadName);
    }
    return displayedLeadsList.join(", ");
  }

  /**
   * Saves the form fields values on to the strip that is selected, and then
   * adds another strip to the list. The new one will have default attributes
   */
  addStrip() {
    // The NEW strip is the one that will be selected.
    //     - Default values, leaving the form elements as they are
    // Make and Select a new Strip
    const newStrip = this.defaultStripValues;
    newStrip.order = this.item.eventStrips.length + 1; // one based indexing
    newStrip.sequenceLead = this.sequenceLead || newStrip.sequenceLead;

    // Add one strip of samples to the middleSample to start the new strip where the previous one ended
    newStrip.middleSample += this.item.ecg.endStripSample - this.item.ecg.startStripSample;
    this.item.eventStrips.push(newStrip);
    this.selectStrip(newStrip);
  }

  /**
   * Puts the strip in a state to be edited
   * @param {Object} strip
   */
  editStrip(strip) {
    this.selectStrip(strip);
    strip.editing = true;
  }

  /**
   * Save changes made to the edited strip
   * @param {Object} strip
   */
  async saveStrip(strip) {
    delete strip.isLoading;
    const stripDeepCopy = cloneDeep(strip);

    const sanitizedStrip = cloneDeep(strip);
    this._sanitizeStrip(sanitizedStrip);

    strip.isLoading = true;

    const {userClassification, timeBase, gain, startTime, endTime, comment, sequenceLead} = sanitizedStrip;

    const updatedStripProperties = {
      userClassification,
      timeBase,
      gain,
      startTime,
      endTime,
      comment,
      sequenceLead,
      displayedLeads: {
        I: sanitizedStrip.displayedLeads.I,
        II: sanitizedStrip.displayedLeads.II,
        III: sanitizedStrip.displayedLeads.III,
      },
      invertedChannels: {
        I: sanitizedStrip.invertedChannels.I,
        II: sanitizedStrip.invertedChannels.II,
        III: sanitizedStrip.invertedChannels.III,
      },
    };

    try {
      if (strip.editing) {
        const {data: updatedStrip} = await this._StripService.updateStrip(strip.id, updatedStripProperties);
        Object.assign(strip, updatedStrip);
      } else {
        const {data: newStrip} = await this._StripService.createStrip(sanitizedStrip);
        Object.assign(strip, newStrip);
      }

      this._StripService.sanitizeMeasurementsOnStrip(strip);
      const measurementsToCreate = this._Markers.MEASUREMENT_TYPES.reduce((accumulator, measurementType) => {
        const measurementsByType = strip.measurements[measurementType].data;
        accumulator.push(...measurementsByType.filter((measurement) => !measurement.id));
        return accumulator;
      }, []);

      if (measurementsToCreate.length !== 0) {
        await this._Markers.createStripMeasurements(strip.id, measurementsToCreate);
      }

      const {measurementsToDelete} = strip;
      if (strip.editing) {
        if (measurementsToDelete && measurementsToDelete.length !== 0) {
          await Promise.all(measurementsToDelete.map((id) => this._Markers.deleteStripMeasurement(id)));
        }
        delete strip.measurementsToDelete;
      }

      // re-fetch measurements list for strip
      if ((measurementsToDelete && measurementsToDelete.length > 0) || measurementsToCreate.length > 0) {
        strip.measurements = this._Markers.DEFAULT_MEASUREMENTS;
        this._Markers
          .getStripMeasurements({stripId: strip.id})
          .then((stripMeasurementsArray) => {
            if (stripMeasurementsArray.length > 0) {
              stripMeasurementsArray.forEach((measurement) => {
                strip.measurements[measurement.name].data.push(measurement);
              });
              this._$rootScope.$emit("update-min-mean-max", strip);
            }
          })
          .then(
            () =>
              new Promise((resolve) => {
                setTimeout(resolve, 10);
              })
          )
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);

      Object.assign(strip, stripDeepCopy);

      const popupTitle = "Failed to save strip";
      const errorMessage = "Unable to save changes for this strip.";

      // display error dialog
      await this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title(popupTitle)
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }

    strip.isLoading = false;
    this.canEditCurrentStrip = false;
    strip.editing = false;

    const sequenceViewer = this._EcgNavigation.getSequenceViewer(
      this.item.ecg.enrollmentId,
      this.item.ecg.sequence
    );
    this._EcgNavigation.drawNavigationBoxes(sequenceViewer, sequenceViewer.boxesToDraw);
    this._$scope.$apply();
  }

  /**
   * Selects a strip from the table by loading its fields into the form so that they can be edited
   * @param {Object} strip
   */
  selectStrip(strip) {
    this.item.eventStrips.forEach((savedStrip) => {
      savedStrip.isSelected = false;
    });
    strip.isSelected = true;
    this.canEditCurrentStrip = !strip.id || strip.editing;

    // Load strip comment into form field model
    this.stripComments = strip.comment;

    // Strip Classification
    if (
      this.stripClassifications &&
      this.stripClassifications.some((e) => e.name === strip.userClassification)
    ) {
      this.selectedStripClassification = strip.userClassification;
    } else {
      this.selectedStripClassification = "";
    }

    // Display toggle- Form elements
    this.channels[0].display = strip.displayedLeads.I;
    this.channels[1].display = strip.displayedLeads.II;
    this.channels[2].display = strip.displayedLeads.III;
    // Config for ECG viewer
    this.ecgStripViewerConfig.displayElements.leads = strip.displayedLeads;

    // Invert Channels - Form elements
    this.channels[0].invert = strip.invertedChannels.I;
    this.channels[1].invert = strip.invertedChannels.II;
    this.channels[2].invert = strip.invertedChannels.III;
    // Config for ECG viewer
    this.ecgStripViewerConfig.inversionStatus.leads = strip.invertedChannels;
    this.sequenceLead = strip.sequenceLead;

    // Override default strip length with the actual length of the strip due to strips not being whole numbers we need to round
    this.ecgStripViewerConfig.stripLength = this.ecgStripViewerConfig.getStripLengthAt25mm(strip);

    // Time base
    this.item.ecg.mmPerSecond = strip.timeBase;

    // Gain
    this.item.ecg.mmPerMillivolt = strip.gain;

    // Redraw Canvas
    this._$rootScope.$emit("select-strip");
    angular.element(() => {
      this._$scope.$apply();
    });
  }

  /**
   * Updates the selected strip in the eventStrips array with a new value for the given field
   * @param {String} field to update
   * @param {Any} newValue
   */
  updateSelectedStrip(field, newValue) {
    const selectedStrip = this.item.eventStrips.find((savedStrip) => savedStrip.isSelected);
    selectedStrip[field] = newValue;
  }

  /**
   * Show warning or error for completion or generation.
   * @param {*} ev
   * @param {String} action markAsCompleted, generateReport
   * @returns {Promise}
   */
  async clickedCompletionOrGeneration(ev, action) {
    const numUnsavedStrips = this.item.eventStrips.filter((strip) => !strip.id).length;
    const numEditingStrips = this.item.eventStrips.filter((strip) => strip.editing).length;
    const numSavedStrips = this.item.eventStrips.length - numUnsavedStrips;
    const userFriendlyStripCount = `${numUnsavedStrips} strip${numUnsavedStrips === 1 ? "" : "s"}`;

    if (
      numUnsavedStrips === 0 &&
      (action === "markAsCompleted" || action === "closeItem" || numSavedStrips > 0) &&
      numEditingStrips === 0
    ) {
      // if this condition is met, don't display a popup.
      await this.saveChanges(ev, action);
      return;
    }

    let headerText = "";
    let buttonText = "";
    let errorMessage = "";
    let warningMessage = "";
    let isRaised = false;
    if (action !== "markAsCompleted" && action !== "closeItem" && numSavedStrips === 0) {
      headerText = "Unable to generate report";
      errorMessage = "Report Generation is not allowed because no strips were saved for this event";
    } else if (numEditingStrips > 0) {
      headerText = "Unable to generate report";
      errorMessage = `Report Generation is not allowed because ${numEditingStrips} ${
        numEditingStrips === 1 ? "strip is" : "strips are"
      } currently being edited`;
    } else if (numUnsavedStrips > 0) {
      if (action === "markAsCompleted") {
        headerText = "Mark as Completed?";
        buttonText = "Mark as Complete";
      } else if (action === "generateReport") {
        headerText = "Generate Report?";
        buttonText = "Generate Report";
        isRaised = true;
      } else {
        headerText = "Save And Close Item?";
        buttonText = "Save And Close Item";
      }
      warningMessage = `You have unsaved changes on ${userFriendlyStripCount}. Any strips with unsaved changes will be lost.`;
    }

    try {
      setTimeout(this._fixMultiPopupBackDrop, 20, headerText);

      const endingAction = await this._$mdDialog.show({
        controller: "ItemConfirmationDialogController",
        controllerAs: "itemConfirmationDialog",
        template: itemConfirmationDialogPug(),
        targetEvent: ev,
        locals: {headerText, buttonText, errorMessage, warningMessage, action, item: this.item, isRaised},
        multiple: true,
      });

      if (endingAction !== "returnToEvent") {
        await this.saveChanges(ev, endingAction);
      }
    } catch (err) {
      if (err !== "Closed item generate or complete dialog") {
        throw err;
      }
    }
  }

  /**
   * This function calculates the strip values, updates the event classification, saves the strip,
   * and then performs the specified ending action
   * @param {object} event The click event that triggered the saving of the strip
   * @param {string} endingAction the action to perform after saving the strips
   * @returns {Promise}
   *
   * @see SRS: BR-72, BR-4353
   */
  async saveChanges(event, endingAction) {
    const eventStripsDeepCopy = cloneDeep(this.item.eventStrips);
    this.item.loading = true;

    // Update event classification
    this.item.userClassification = this.selectedUserRhythmClassification;

    const propertiesToUpdate = {
      userClassification: this.selectedUserRhythmClassification,
      meetsMdnCriteria: this.meetsMdnCriteria,
      technicianFindings: this.technicianFindings,
    };

    if (endingAction === "markAsCompleted") {
      propertiesToUpdate.completed = true;
    }

    if (!this.features.saveInProgressChanges) {
      delete propertiesToUpdate.meetsMdnCriteria;
      delete propertiesToUpdate.technicianFindings;
    }

    // Sort the strip list by intended order in preparation for report
    this._StripService.orderStripList(this.item.eventStrips, "order");

    // Prepare strips for updating their order and for reports by sanitizing them
    // * set each stripOrder property by index, the order property could be wrong after unsaved strips are filter out
    this.stripsForReport = [];
    const stripsToUpdate = this.item.eventStrips
      .filter((strip) => !!strip.id)
      .map((strip, index) => {
        if (!strip.userClassification) {
          strip.userClassification = this.item.userClassification;
        }

        // important for report generation
        this._StripService.sanitizeMeasurementsOnStrip(strip);
        this.stripsForReport.push(strip);

        return {id: strip.id, properties: {stripOrder: index + 1}};
      });

    try {
      await this._EventService.updateEvent(this.item, propertiesToUpdate);

      if (stripsToUpdate.length > 0) {
        // update the order of the strips on the event
        await this._StripService.updateStrips(stripsToUpdate);
      }

      // Combine the Baseline strips onto the strips array
      if (this.item.listedStrips) {
        this.stripsForReport.push(...this.item.listedStrips.filter((strip) => strip.includeInReport));
      }

      switch (endingAction) {
        case "generateReport":
          await this.generateReport();
          break;
        case "editReport":
          await this.saveChangesToGeneratedReport();
          break;
        case "closeItem":
        case "markAsCompleted":
          await this.closeItem();
          break;
        default:
      }

      this.item.loading = false;
    } catch (err) {
      this.item.loading = false;
      if (err?.message !== "Return to event") {
        let popupTitle = "Failed to save changes";
        let errorMessage = "Unable to save changes for the item.";

        if (endingAction === "generateReport") {
          popupTitle = "Failed to Generate Report";
        }

        if (err?.message) {
          errorMessage = err.message;
        }

        // display error dialog
        await this._$mdDialog.show(
          this._$mdDialog
            .alert()
            .title(popupTitle)
            .htmlContent(
              `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
                `${errorMessage}</p>`
            )
            .ok("Ok")
        );
      }
    }

    // When the dialog is closed or following an error
    // Restore strips without reassigning the original pointer to eventStrips
    eventStripsDeepCopy.forEach((strip, i) => {
      this.item.eventStrips[i] = strip;
    });
  }

  async updateTriageEvent({complete = false} = {}) {
    // Update event classification
    this.item.userClassification = this.selectedUserRhythmClassification;

    const propertiesToUpdate = {
      completed: complete,
      userClassification: this.selectedUserRhythmClassification,
      meetsMdnCriteria: this.meetsMdnCriteria,
      technicianFindings: this.technicianFindings,
    };

    if (!this.features.saveInProgressChanges) {
      delete propertiesToUpdate.meetsMdnCriteria;
      delete propertiesToUpdate.technicianFindings;
    }

    const updateEventPromise = this._EventService.updateEvent(this.item, propertiesToUpdate);

    // Sort the strip list by intended order
    this._StripService.orderStripList(this.item.eventStrips, "order");

    // Prepare strips for updating their order
    // * set each stripOrder property by index, the order property could be wrong after unsaved strips are filter out
    const stripsToUpdate = this.item.eventStrips
      .filter((strip) => !!strip.id)
      .map(({id}, index) => ({id, properties: {stripOrder: index + 1}}));

    this.selectedUserRhythmClassification = null;
    this.item.loading = true;

    try {
      await updateEventPromise;

      if (stripsToUpdate.length > 0) {
        // update the order of the strips on the event
        await this._StripService.updateStrips(stripsToUpdate);
      }

      // If the item is not being marked as completed, it is being marked for review,
      // in which case all assignments still need to be deleted
      if (!complete && this.features.itemAssignments) {
        await this._ItemAssignmentService.clearAssignments(
          this.item.id,
          this.item.assignedUsers,
          this.item.facilityId
        );
      }

      this.item.loading = false;
      await this._InboxItemService.deselectItem();
    } catch (err) {
      this.item.loading = false;
      const popupTitle = "Failed to save changes";
      const errorMessage = "Unable to save changes for the item.";

      // display error dialog
      await this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title(popupTitle)
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }
  }

  /**
   * @returns {Promise} closes the popup and returns to the open item
   *
   * @TODO possibly refresh lock on open item after hiding the dialog
   * @see SRS: BR-72
   */
  returnToItem() {
    return this._$mdDialog.hide();
  }

  closeItem() {
    return this._$mdDialog.hide().then(() => this._InboxItemService.deselectItem());
  }

  async generateReportData() {
    const maxNumStrips = 150;
    if (this.stripsForReport.length > maxNumStrips) {
      this.loadingReport = false;
      throw new Error(`Generated reports may not include more than ${maxNumStrips} strips`);
    }

    this.stripsForReport.forEach((strip) => {
      // Clean up measurements object to maintain existing format
      // Measurements will not be re-fetched, in order to ensure that the data
      // displayed on the item matches the data on the report

      Object.keys(strip.measurements).forEach((measurementName) => {
        delete strip.measurements[measurementName].min;
        delete strip.measurements[measurementName].mean;
        delete strip.measurements[measurementName].max;
      });
    });

    // Save the event classification onto the strip blob
    await Promise.all(
      this.stripsForReport.map((strip) => {
        return this._StripService.getStripEvent(strip).then((stripEvent) => {
          strip.eventClassification = this._EventService.getEventClassification(stripEvent);
        });
      })
    );

    const [beatMarkers, logoFilename] = await Promise.all([
      this._Markers.getBeatMarkerCountsForStrips(this.stripsForReport),
      this._Facility.getLogoFilename(this.stripsForReport[0].facilityId),
    ]);

    return {beatMarkers, logoFilename};
  }

  /**
   * @returns {Promise} generates Single Episode Report
   *
   * @see SRS: BR-1006
   */
  async generateReport() {
    let reportId;

    this.loadingReport = true;

    try {
      const {beatMarkers, logoFilename} = await this.generateReportData();

      const reportResponse = await this._GeneratedReportService.generateSingleEpisodeReport(
        this.item,
        this.stripsForReport,
        beatMarkers,
        logoFilename,
        this.technicianFindings,
        this.meetsMdnCriteria
      );

      reportId = reportResponse.data.id;
      if (!this.item.completed) {
        await this._InboxItemService.complete();
      }

      this._$state.go("Generated Report", {type: "single-episode", reportId: reportId});
      this.loadingReport = false;
    } catch (err) {
      this.loadingReport = false;
      let errorMessage;
      if (typeof err === "string") {
        errorMessage = err;
      } else if (err.message) {
        errorMessage = err.message;
      } else {
        errorMessage = "Report could not be generated";
      }

      this._$mdDialog.show(
        this._$mdDialog
          .alert()
          .title("Error creating report")
          .htmlContent(
            `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
              `${errorMessage}</p>`
          )
          .ok("Ok")
      );
    }
  }

  async saveChangesToGeneratedReport() {
    this.loadingReport = true;

    const {beatMarkers, logoFilename} = await this.generateReportData();
    const propertiesToUpdate = {
      meetsMdnCriteria: this.meetsMdnCriteria,
      comment: this.technicianFindings,
      strips: this.stripsForReport,
      beatMarkers,
      logoFilename,
    };

    await this._GeneratedReportService.saveChangesToReport(this.generatedReportId, propertiesToUpdate);

    this.loadingReport = false;
    await this.closeItem();
  }

  /**
   * Returns true if the two classifications start with the same word but are not exactly equal
   * "Tachy"          and  "Tachy >150BPM"  returns true
   * "Tachy >140BPM"  and  "Tachy >150BPM"  returns true
   * "Tachy"          and  "Tachy"          returns false
   *
   * @param {String} optionA
   * @param {String} optionB
   * @returns {Boolean}
   */
  isSimilar(optionA, optionB) {
    return (
      optionA && optionB && optionA !== optionB && this.firstWordOf(optionA) === this.firstWordOf(optionB)
    );
  }

  getSimilarityTooltip(userClassification, option) {
    let tooltip = "";
    if (this.isSimilar(userClassification, option)) {
      tooltip = `This event is classified as ${userClassification}, but the study thresholds have since changed.`;
    }
    return tooltip;
  }

  /**
   * Returns the first word of the classification
   * @param {String} option - "VTach" or "Normal 30-150BPM"
   * @returns {String} "VTach" or "Normal"
   */
  firstWordOf(option) {
    return option.split(/\s/)[0];
  }

  /// Private Functions ///

  _init() {
    this.fullViewerConfig.timeZone = this.item.study?.timeZone;
    this.selectedUserRhythmClassification = this.item.userClassification;

    if (this.features.saveInProgressChanges) {
      this.technicianFindings = this.item.technicianFindings;
      this.meetsMdnCriteria = this.item.meetsMdnCriteria;

      // these variables are being shared from the parent component we need to manually handle the on change event
      this.technicianFindingsModel = this.item.technicianFindings;
      this.meetsMdnCriteriaModel = this.item.meetsMdnCriteria;
    }

    this.eSignEnabled = this._WorkflowsService.workflowSettings[this.item.facilityId]?.eSignEnabled;

    // Update Displayed Chips for Tachy, Brady, and Normal based on current device settings
    const {tachyBpm, bradyBpm} = this.item;
    if (tachyBpm && bradyBpm) {
      let index;
      if ((index = this.userRhythmClassifications.indexOf("Tachy")) !== -1) {
        this.userRhythmClassifications[index] = `Tachy >${tachyBpm}BPM`;
      }
      if ((index = this.userRhythmClassifications.indexOf("Brady")) !== -1) {
        this.userRhythmClassifications[index] = `Brady <${bradyBpm}BPM`;
      }
      if ((index = this.userRhythmClassifications.indexOf("Normal")) !== -1) {
        this.userRhythmClassifications[index] = `Normal ${bradyBpm}-${tachyBpm}BPM`;
      }
    }

    try {
      if (this.item.eventStrips.length === 0) {
        // Add a strip with default values
        this.item.eventStrips.push(this.defaultStripValues);
      }
      this.item.eventStrips.forEach((strip) => {
        // Set displayedLeads and invertedChannels to default values if null
        if (!strip.displayedLeads) {
          strip.displayedLeads = {
            I: this.channels[0].display,
            II: this.channels[1].display,
            III: this.channels[2].display,
          };
        }
        if (!strip.invertedChannels) {
          strip.invertedChannels = {
            I: this.channels[0].invert,
            II: this.channels[1].invert,
            III: this.channels[2].invert,
          };
        }

        strip.order = strip.stripOrder || 1; // one-based indexing

        // Valid timestamps are 2010 or later
        strip.validStartTime = moment(strip.startTime).year() >= 2010;

        // Use strip service to calculate middleSample
        strip.middleSample = this._StripService.getStripMidpoint(strip, this.item.ecg);
        strip.isSelected = false;
      });
    } catch (error) {
      console.error(error);
    }

    this._setClassificationsForFacility()
      .then(
        () =>
          new Promise((resolve) => {
            setTimeout(resolve, 10);
          })
      )
      .then(() => {
        // This has to wait for strip classifications to complete
        // Wait 10 ms to avoid a race condition that can occur if the ECG Viewer
        // has not started listening for select-strip (See BR-5429)
        this.selectStrip(this.item.eventStrips[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * @returns {Promise} ECG classifications for the facility
   * @private
   * @see SRS: BR-71
   */
  _setClassificationsForFacility() {
    return this._StripClassificationsService
      .getAll({facilityId: this.item.facilityId})
      .then((classifications) => {
        this.stripClassifications = classifications;
        return classifications;
      });
  }

  /**
   * Destructively sanitize a strip for the backend
   * @param {Object} strip
   * @return {Object} sanitized strip
   */
  _sanitizeStrip(strip) {
    this._StripService.sanitizeStrip(strip);
    return strip;
  }

  /**
   * Calculate the start and end time for the ecg strip viewer using the ecg start time,
   * the start and end strip samples, and samples per second
   *
   * @returns {Object} with startTime and endTime
   */
  _getDefaultStartAndEndTime() {
    let samplesPerSecond;
    let startSample;
    let endSample;

    if (this.item.ecg.startStripSample && this.item.ecg.endStripSample && this.item.ecg.samplesPerSecond) {
      samplesPerSecond = this.item.ecg.samplesPerSecond;
      startSample = this.item.ecg.startStripSample;
      endSample = this.item.ecg.endStripSample;
    } else {
      samplesPerSecond = 1000000 / this.item.ecg.samplePeriod;
      const timeBase = this.item.ecg.mmPerSecond || 25;
      const secondsInViewer = this._Config.ecgViewerStripLength || 6;
      const mmHorizontal = 25 * secondsInViewer;
      const samplesToDisplay = (mmHorizontal / timeBase) * samplesPerSecond;

      startSample = Math.floor(this.item.ecg.eventSample - samplesToDisplay / 2);
      endSample = Math.floor(this.item.ecg.eventSample + samplesToDisplay / 2);

      if (this.item.ecg.leads[0] && endSample >= this.item.ecg.leads[0].totalSamples) {
        endSample = this.item.ecg.leads[0].totalSamples - 1;
        startSample = endSample + 1 - samplesToDisplay;
      }

      if (startSample < 0) {
        startSample = 0;
        endSample = startSample + samplesToDisplay - 1;
      }
    }
    const startTime = moment(this.item.ecg.startTime).add(startSample / samplesPerSecond, "seconds");
    const endTime = moment(this.item.ecg.startTime).add(endSample / samplesPerSecond, "seconds");
    return {startTime, endTime};
  }

  _fixMultiPopupBackDrop(textToSearchFor) {
    const dialog = Array.from(document.getElementsByClassName("md-dialog-container")).find((e) =>
      e.textContent.toUpperCase().includes(textToSearchFor.toUpperCase())
    );
    let computedStyle = window.getComputedStyle(dialog);
    let currentIndex = Number(computedStyle.getPropertyValue("z-index"));
    dialog.style["z-index"] = `${currentIndex + 2}`;

    const dialogBackdrop = document.getElementsByClassName("md-dialog-backdrop")[0];
    computedStyle = window.getComputedStyle(dialogBackdrop);
    currentIndex = Number(computedStyle.getPropertyValue("z-index"));
    dialogBackdrop.style["z-index"] = `${currentIndex + 2}`;
  }
}
